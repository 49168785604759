<template>
  <div class="start-page__reg start-page-reg">
    <h1 class="start-page-reg__title">Регистрация</h1>
    <div class="start-page-reg__content" v-if="step === 'code'">
      <Input
        placeholder="Например: example@mail.ru"
        header="Электронная почта"
        v-model.trim="formData.emailValue"
        type="email"
        :error="v$.formData.emailValue.$errors.length !== 0"
        ref="email"
        @blur="checkEmails"
      />
      <div
        class="start-page-reg__captcha"
        v-if="!isCaptchaLoading"
        :class="{ disabled: !reloadCaptchaAgree }"
      >
        <img
          :src="`data:image/gif;base64,${captchaData.imgBase64}`"
          class=""
          alt="captcha"
        />
        <Icon type="cropper" @click="reloadCaptcha" />
      </div>
      <Skeleton class="start-page-reg__captcha" v-else />
      <Input
        header="Введите код с каптчи"
        type="text"
        v-model.trim="formData.captchaValue"
        mask="####"
        :error="v$.formData.captchaValue.$errors.length !== 0"
      />
    </div>
    <div class="start-page-reg__content" v-if="step === 'register'">
      <span class="start-page-reg__code" v-if="enableEmail">
        На Ваш Email отправлен уникальный PIN-код
      </span>
      <Input
        placeholder="Например: example@mail.ru"
        header="Электронная почта"
        v-model.trim="formData.emailValue"
        type="email"
        disabled
      />
      <Input
        v-if="enableEmail"
        header="Введите PIN-код"
        type="text"
        v-model.trim="formData.emailCode"
        mask="######"
        :error="v$.formData.emailCode.$errors.length !== 0"
        ref="pinCode"
      />
      <div class="start-page-reg__agreement">
        <Checkbox label="Согласие на обработку" v-model="isAgree" />
        <a href="https://ligastudentov.com/privacy" target="_blank">
          персональных данных
        </a>
      </div>
    </div>
    <div class="start-page__btns">
      <Button large v-if="step === 'register'" @click="back" secondary>
        Назад
      </Button>
      <div
        :class="{ isTooltipShown: isBtnDisabled && step === 'register' }"
        data-tooltip="Необходимо подтвердить согласие на обработку персональных данных и/или ввести PIN-код"
      >
        <Button
          large
          @click.enter="handleSubmit"
          :loading="isBtnLoading"
          :disabled="!isValidEmail || isBtnDisabled"
        >
          {{
            this.step === "code"
              ? this.enableEmail
                ? "Отправить код"
                : "Зарегистрироваться"
              : this.enableEmail
              ? "Зарегистрироваться"
              : "Завешить регистрацию"
          }}
        </Button>
      </div>
    </div>
    <div class="start-page__link">
      <p>
        Уже есть аккаунт?
        <router-link :to="{ name: 'Auth' }">Войти</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import Button from "@/components/Blocks/Button";
import { createNamespacedHelpers, mapGetters } from "vuex";
import Skeleton from "@/components/Blocks/Skeleton";
const { mapActions } = createNamespacedHelpers("user");
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import Checkbox from "@/components/Blocks/Checkbox";
import { checkTrueEmails } from "@/utils/helpers";
import Icon from "@/components/Blocks/Icon.vue";

export default {
  name: "Registration",
  components: { Icon, Checkbox, Skeleton, Button, Input },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      step: "code",
      isCaptchaLoading: false,
      isAgree: false,
      isBtnLoading: false,
      reloadCaptchaAgree: true,
      isValidEmail: "",
      formData: {
        emailValue: this.savedEmail ?? "",
        captchaValue: "",
        emailCode: "",
      },
      captchaData: {
        imgBase64: "",
        secret: "",
      },
    };
  },
  props: {
    authStatus: {
      type: String,
      default: "reg",
    },
    savedEmail: [String],
  },
  validations() {
    if (this.enableEmail) {
      validations.registration.emailCode =
        this.step === "register"
          ? {
              required: helpers.withMessage(
                "Обязательно к заполнению",
                required
              ),
              minLength: helpers.withMessage(
                "Поле должно состоять из 6 символов",
                minLength(6)
              ),
              maxLength: helpers.withMessage(
                "Поле должно состоять из 6 символов",
                maxLength(6)
              ),
            }
          : {};
    }
    return { formData: validations.registration };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.registration;
    },
    isBtnDisabled() {
      return this.step === "code"
        ? !this.formData.emailValue || !this.formData.captchaValue
        : !this.enableEmail
        ? !this.isAgree
        : this.isAgreeStatus;
    },
    isAgreeStatus() {
      return (
        !this.isAgree || !this.formData.emailValue || !this.formData.emailCode
      );
    },
    ...mapGetters(["enableEmail"]),
  },
  methods: {
    checkEmails() {
      if (this.checkTrueEmails(this.formData.emailValue)) {
        this.$store.commit("pushToTray", {
          text: "Недопустимый адрес почты. Введите другую почту.",
          type: "error",
        });
        this.isValidEmail = false;
      } else {
        this.isValidEmail = true;
        this.$emit("email-changed", this.formData.emailValue);
      }
    },
    async enterModal(e) {
      if (this.$route.name === "Registration") {
        if (!this.isValidEmail) return;
        if (this.authStatus === "reg" && e.key === "Enter") {
          await this.checkEmails();
          if (!this.isValidEmail) return;
          if (this.step === "code") {
            if (this.isBtnDisabled) return;
            await this.handleSubmit();
          } else {
            if (!this.isAgree) return;
            await this.handleSubmit();
          }
        }
      }
    },
    async handleSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      this.isBtnLoading = true;
      try {
        if (this.step === "code") {
          await createRequest(requestConfigs.POSTCodeRequest, {
            jsonPayload: {
              login: this.formData.emailValue,
              captcha: this.formData.captchaValue,
              captcha_secret: this.captchaData.secret,
              type_code: "signup",
            },
          });
          this.step = "register";
          this.isBtnLoading = false;
        } else if (this.step === "register") {
          await createRequest(requestConfigs.POSTSignUp, {
            jsonPayload: {
              email: this.formData.emailValue,
              code: this.enableEmail ? this.formData.emailCode : "111111",
            },
          }).then((result) => {
            this.$store.dispatch("user/userAuth", {
              token: result.data.token,
            });
          });
          this.isBtnLoading = false;
        }
      } catch (error) {
        if (error.errors[0].error_name === "telegram") {
          this.$store.commit("user/killUser");
          this.$store.commit("pushToTray", {
            text: "Высокая нагрузка на сервер. Попробуйте через 30 секунд",
            type: "error",
          });
        } else
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        this.isBtnLoading = false;
      }
    },
    captchaRequest() {
      this.isCaptchaLoading = true;
      this.getCaptcha()
        .then((result) => {
          this.captchaData = result;
        })
        .finally(() => {
          this.isCaptchaLoading = false;
        });
    },
    reloadCaptcha() {
      this.captchaRequest();
      this.reloadCaptchaAgree = false;
      const timer = setTimeout(() => {
        this.reloadCaptchaAgree = true;
        clearTimeout(timer);
      }, 60000);
    },
    back() {
      this.step = "code";
      this.formData.emailCode = "";
      this.isAgree = false;
    },
    ...mapActions(["getCaptcha"]),
    checkTrueEmails,
  },
  watch: {
    authStatus() {
      if (!this.captchaData.imgBase64) this.captchaRequest();
    },
    step() {
      this.step === "code"
        ? this.$refs["email"]?.autofocus()
        : this.$refs["pinCode"]?.autofocus();
    },
  },
  beforeMount() {
    if (this.authStatus !== "reg") return;
    if (!this.captchaData.imgBase64) this.captchaRequest();
  },
  mounted() {
    window.addEventListener("keydown", this.enterModal);
    this.checkEmails();
    this.$refs["email"].autofocus();
  },
  updated() {
    if (this.step === "code") {
      if (!this.$refs["email"].value) {
        this.$refs["email"]?.autofocus();
      }
    } else {
      if (this.enableEmail) {
        if (!this.$refs["pinCode"].value) {
          this.$refs["pinCode"]?.autofocus();
        }
      }
    }
  },
};
</script>

<style scoped></style>
