<template>
  <div class="social-active-achievement-item">
    <div class="social-active-achievement-item__file">
      <div class="social-active-achievement-item__img">
        <Skeleton v-if="isPictureLoading" />
        <img :src="imgSrc" alt="" v-else @click="openPopup" />
      </div>
      <div class="social-active-achievement-item__name">
        <a @click="openPopup">
          {{ data.title }}
        </a>
        <p>
          Файл загружен пользователем:
          {{ createdDate }}
        </p>
      </div>
    </div>
    <div class="social-active-achievement-item__info">
      <p
        v-for="item in mountedCurrentTreeItem ?? currentTreeItem"
        :key="item.id"
      >
        {{ item.field_name }}:
        <span>{{ item.title }}</span>
      </p>
      <p>
        Год: <span>{{ data.year }}</span>
      </p>
      <p>
        Количество баллов: <span>{{ data.project_tree_points }}</span>
      </p>
    </div>
    <div class="social-active-achievement-item__controls">
      <Comment
        v-if="data.status === 40 && data.comment_expert"
        class="social-active-achievement-item__comment"
        :comment="data.comment_expert"
        status="Отклонено. "
        project-type="tg"
      />
      <Status v-else-if="data.status === 50" :statusValue="50" text="Принято" />
      <Status
        v-else-if="data.status === 0"
        :statusValue="1"
        text="Ожидает проверки"
      />
      <Button
        @click="openPopupDelete"
        class="social-active-achievement-item__btn"
        secondary
        v-if="
          !data?.comment_expert &&
          !$parent.isDisabled &&
          data?.status === 0 &&
          isCanDelete
        "
        :loading="isDeleteBtnLoading"
      >
        Удалить
      </Button>
      <span
        class="social-active-achievement-item__desc"
        v-if="data?.status === 0 && !isCanDelete"
      >
        Достижение участвует в конкурсе, удалить нельзя
      </span>
    </div>

    <PopupViewCertificate
      v-if="isPopupOpen"
      :image_url="data?.url_original"
      @close-popup="isPopupOpen = false"
    />
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { getImg, parseParentTrees } from "@/utils/helpers";
import Skeleton from "@/components/Blocks/Skeleton";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Comment from "@/components/Blocks/Comment";
import PopupViewCertificate from "@/components/Popups/PopupViewCertificate";
import Status from "@/components/Blocks/Status";
export default {
  name: "SocialActiveAchievementItem",
  components: { Status, PopupViewCertificate, Comment, Skeleton, Button },
  data() {
    return {
      image: "",
      isPictureLoading: false,
      isPopupOpen: false,
      fieldsName: ["Область деятельности", "Критерий", "Уровень", "Статус"],
    };
  },
  props: {
    delete: Boolean,
    currentStatus: Number,
    data: {
      type: Object,
      default: () => ({}),
    },
    currentStatementId: Number,
    projectId: Number,
    allFields: {
      type: Array,
      default: () => [],
    },
    currentTreeItem: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openPopup() {
      this.isPopupOpen = true;
    },
    openPopupDelete() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupDeleteAchievement",
        isShown: true,
        props: {
          type: "achievements",
          media_id: this.data.id,
          id: this.currentStatementId,
        },
      });
    },
    parseParentTrees,
  },
  computed: {
    isCanDelete() {
      return (
        this.currentStatus === 0 ||
        this.currentStatus === 1 ||
        this.currentStatus === 35
      );
    },
    imgSrc() {
      return this.hashMedia
        ? `data:image/png;base64,${
            this.data?.image ? this.data.image : this.image
          }`
        : this.data?.image
        ? this.data.image
        : this.image;
    },
    createdDate() {
      return moment(this.data?.created_at).format("DD.MM.YYYY");
    },
    mountedCurrentTreeItem() {
      return parseParentTrees(this.allFields, this.data?.project_tree_id)
        .reverse()
        .map((item, index) => {
          item.field_name = this.fieldsName[index];
          return item;
        });
    },
    ...mapState(["statementsList", "isDeleteBtnLoading", "storage"]),
    ...mapGetters(["hashMedia"]),
  },
  mounted() {
    if (!this.data?.image) {
      if (this.hashMedia) {
        this.isPictureLoading = true;
        getImg(this.data?.url_thumb)
          .then((result) => {
            this.image = result;
          })
          .finally(() => {
            this.isPictureLoading = false;
          });
      } else
        this.data?.url_thumb
          ? (this.image = this.data?.url_thumb)
          : (this.image = this.data?.url_original);
    }
  },
};
</script>

<style lang="scss">
.social-active-achievement-item {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px;
  transition: all 0.1s ease-in;
  border-top: 1px solid var(--background--btn--profile--hover);
  border-bottom: 1px solid var(--background--btn--profile--hover);
  cursor: pointer;
  @include adaptive(tablet-big) {
    grid-template-columns: 1fr 1fr min-content;
  }
  @include adaptive(tablet-small) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  &:hover {
    background-color: var(--background--application--review);
    .social-active-achievement-item__btn {
      @media (min-width: 767px) {
        display: block;
      }
    }
  }
  &__file {
    display: flex;
    align-items: center;
    gap: 16px;
    @include link();
    & a {
      text-decoration: none;
      @include adaptive(phone) {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }
    & p {
      color: var(--banner--color);
      font-size: 1.4rem;
      line-height: 1.7rem;
      @include adaptive(phone) {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
  }
  &__img {
    position: relative;
    min-height: 150px;
    min-width: 150px;
    border-radius: 5px;
    .skeleton-basic {
      min-height: 150px;
      min-width: 150px;
      border-radius: 5px;
    }
    & img {
      @include full-absolute();
      object-fit: cover;
      border-radius: 5px;
    }
    @include adaptive(phone) {
      .skeleton-basic {
        min-height: 90px;
        min-width: 90px;
      }
      min-height: 90px;
      min-width: 90px;
    }
  }
  &__info {
    color: var(--banner--color);
    p {
      font-weight: 700;
    }
    span {
      font-weight: 400;
    }
    @include adaptive(phone) {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
    grid-gap: 20px;
    .status {
      margin-top: 20px;
    }
    @include adaptive(tablet-small) {
      align-items: center;
      width: 100%;
      order: -1;
    }
  }
  &__btn {
    justify-self: end;
    display: none;
    @include adaptive(tablet-big) {
      display: block;
      padding: 6px 12px;
      font-size: 1.2rem;
    }
  }
  &__desc {
    color: orange;
  }
  &__name {
    &--disabled {
      pointer-events: none;
      color: var(--event--hover) !important;
    }
  }
  &__comment {
    @include adaptive(tablet-big) {
      grid-column: 1 / -1;
      grid-row: 1 / 2;
    }
    @include adaptive(tablet-small) {
      order: -1;
    }
  }
}
</style>
