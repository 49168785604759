<template>
  <div class="housing-stock">
    <Title
      :label="`Анкета «Молодежный жилищный конкурс»/ ${currentLabel}`"
      icon
      :application-info="currentCategoryData"
    />
    <div class="housing-stock__header">
      <TopSidebar :tabData="formattedHousingStockBar" />
    </div>
    <Comment
      class="housing-stock__comment"
      v-if="currentCategoryData?.comment_employee && currentStatusForComment"
      :comment="currentCategoryData?.comment_employee"
      project-type="mzk"
    />
  </div>
  <router-view :project="true" :currentProject="currentProject" />
</template>

<script>
import Title from "@/components/Blocks/Title";
import housingStockBar from "@/enums/housingStockBar";
import { mapGetters, mapState } from "vuex";
import Comment from "@/components/Blocks/Comment";
import TopSidebar from "@/components/Blocks/TopSidebar";
export default {
  name: "HousingStockForm",
  components: { TopSidebar, Comment, Title },
  data() {
    return {
      housingStockBar,
      commentStatuses: [27, 28, 35, 40],
    };
  },
  computed: {
    formattedHousingStockBar() {
      let buffer = JSON.parse(JSON.stringify(this.housingStockBar));

      return buffer.map((item) => {
        if (item.key === "social-activity") {
          item.disabled = !this.disabledFields["isHSProfileFilled"];
        }
        if (item.key === "labor-activity") {
          item.disabled =
            !this.disabledFields["isHSProfileFilled"] ||
            !this.disabledFields["isSocialActivityFilled"];
        }
        if (item.key === "personal") {
          item.disabled =
            !this.disabledFields["isHSProfileFilled"] ||
            !this.disabledFields["isSocialActivityFilled"] ||
            !this.disabledFields["isLaborActivityFilled"];
        }
        if (item.key === "docs") {
          item.disabled =
            !this.disabledFields["isHSProfileFilled"] ||
            !this.disabledFields["isSocialActivityFilled"] ||
            !this.disabledFields["isLaborActivityFilled"] ||
            !this.disabledFields["isHSPersonalDataFilled"];
        }
        return item;
      });
    },
    currentProject() {
      return Object.values(this.projectsList)?.find((item) => {
        return this.currentCategoryProject
          ? item.id === this.currentCategoryProject
          : item.id === this.currentCategoryData?.info_project?.id;
      });
    },
    currentLabel() {
      return this.currentProject?.settings?.categories.find((item) => {
        return this.currentCategoryId
          ? item.category_id === this.currentCategoryId
          : item.category_id === this.currentCategoryData?.info_category?.id;
      })?.title;
    },
    currentStatusForComment() {
      return this.currentCategoryData?.status?.key
        ? this.commentStatuses.includes(this.currentCategoryData?.status?.key)
        : false;
    },
    ...mapGetters(["projectsList"]),
    ...mapState("user", ["disabledFields"]),
    ...mapState([
      "currentCategoryData",
      "currentCategoryProject",
      "currentCategoryVersion",
      "currentCategoryId",
    ]),
  },
  mounted() {
    this.$store.commit("setNominationValue", [
      "isOldApplication",
      this.currentCategoryData?.version
        ? this.currentProject.version !== this.currentCategoryData?.version
        : this.currentProject.version !== this.currentCategoryVersion,
    ]);
  },
};
</script>

<style lang="scss">
.housing-stock {
  &__comment {
    margin-bottom: 24px;
    @include adaptive(tablet-big) {
      margin-bottom: 15px;
    }
  }
  &__header {
    margin-bottom: 40px;
    @include adaptive(tablet-big) {
      margin-bottom: 30px;
    }
    @include adaptive(phone) {
      margin-bottom: 20px;
    }
  }
}
</style>
