<template>
  <div class="start-page__auth start-page-auth">
    <h1 class="start-page-auth__title">Вход</h1>
    <div class="start-page-auth__content" v-if="step === 'code'">
      <Input
        placeholder="Например: example@mail.ru"
        v-model.trim="formData.emailValue"
        :error="v$.formData.emailValue.$errors.length !== 0"
        header="Электронная почта"
        type="email"
        ref="email"
        @blur="checkEmails"
      />
      <div
        class="start-page-reg__captcha"
        v-if="!isCaptchaLoading"
        :class="{ disabled: !reloadCaptchaAgree }"
      >
        <img
          :src="`data:image/gif;base64,${captchaData.imgBase64}`"
          class=""
          alt="captcha"
        />
        <Icon type="cropper" @click="reloadCaptcha" />
      </div>
      <Skeleton class="start-page-reg__captcha" v-else />
      <Input
        header="Введите код с каптчи"
        v-model.trim="formData.captchaValue"
        mask="####"
        :error="v$.formData.captchaValue.$errors.length !== 0"
        type="text"
      />
    </div>
    <div class="start-page-reg__content" v-if="step === 'logIn'">
      <span class="start-page-reg__code">
        В Ваш Telegram/Email отправлен уникальный PIN-код
      </span>
      <Input
        placeholder="Например: example@mail.ru"
        header="Электронная почта"
        v-model.trim="formData.emailValue"
        type="email"
        disabled
      />
      <Input
        header="Введите PIN-код"
        type="text"
        v-model.trim="formData.emailCode"
        mask="######"
        :error="v$.formData.emailCode.$errors.length !== 0"
        ref="pinCode"
      />
      <Button
        v-if="isResendAgree"
        @click="sendCodeToEmail"
        class="start-page-reg__resend"
      >
        Отправить код на Email
      </Button>
      <p @click="openAuthInfoPopup">Если не приходит код в Telegram/Email</p>
    </div>
    <div class="start-page__btns">
      <Button large v-if="step === 'logIn'" @click="back" secondary>
        Назад
      </Button>
      <Button
        large
        @click.enter="handleSubmit"
        :loading="isBtnLoading"
        :disabled="!isValidEmail || isBtnDisabled"
      >
        {{ this.step === "code" ? "Отправить код" : "Войти" }}
      </Button>
    </div>
    <div class="start-page__link">
      <p>
        У вас еще нет аккаунта?
        <router-link :to="{ name: 'Registration' }">
          Зарегистрироваться
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import Button from "@/components/Blocks/Button";
import { createNamespacedHelpers, mapGetters } from "vuex";
import Skeleton from "@/components/Blocks/Skeleton";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import useVuelidate from "@vuelidate/core";
const { mapActions } = createNamespacedHelpers("user");
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { checkTrueEmails } from "@/utils/helpers";
import Icon from "@/components/Blocks/Icon.vue";

export default {
  name: "Auth",
  components: { Icon, Skeleton, Button, Input },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      step: "code",
      isCaptchaLoading: false,
      isBtnLoading: false,
      reloadCaptchaAgree: true,
      formData: {
        emailValue: this.savedEmail ?? "",
        captchaValue: "",
        emailCode: "",
      },
      captchaData: {
        imgBase64: "",
        secret: "",
      },
      isValidEmail: "",
      timer: null,
      isResendAgree: false,
    };
  },
  props: {
    authStatus: {
      type: String,
      default: "auth",
    },
    savedEmail: [String],
  },
  validations() {
    validations.registration.emailCode =
      this.step === "logIn"
        ? {
            required: helpers.withMessage("Обязательно к заполнению", required),
            minLength: helpers.withMessage(
              "Поле должно состоять из 6 символов",
              minLength(6)
            ),
            maxLength: helpers.withMessage(
              "Поле должно состоять из 6 символов",
              maxLength(6)
            ),
          }
        : {};
    return { formData: validations.registration };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.registration;
    },
    isBtnDisabled() {
      return this.step === "code"
        ? !this.formData.emailValue || !this.formData.captchaValue
        : !this.formData.emailValue || !this.formData.emailCode;
    },
    ...mapGetters(["resendCodeSeconds"]),
  },
  methods: {
    back() {
      this.step = "code";
      this.formData.emailCode = "";
      this.isAgree = false;
    },
    checkEmails() {
      if (this.checkTrueEmails(this.formData.emailValue)) {
        this.$store.commit("pushToTray", {
          text: "Недопустимый адрес почты. Введите другую почту.",
          type: "error",
        });
        this.isValidEmail = false;
      } else {
        this.isValidEmail = true;
        this.$emit("email-changed", this.formData.emailValue);
      }
    },
    async enterModal(e) {
      if (this.$route.name === "Auth") {
        if (this.authStatus === "auth" && e.key === "Enter") {
          await this.checkEmails();
          if (!this.isValidEmail) return;
          if (this.isBtnDisabled) return;
          await this.handleSubmit();
        }
      }
    },
    async handleSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      this.isBtnLoading = true;
      try {
        if (this.step === "code") {
          await this.sendCode();
          this.step = "logIn";
          this.isBtnLoading = false;
        } else if (this.step === "logIn") {
          await createRequest(requestConfigs.POSTSignIn, {
            jsonPayload: {
              email: this.formData.emailValue,
              code: this.formData.emailCode,
              type: "student",
            },
          }).then((result) => {
            this.$store.dispatch("user/userAuth", {
              token: result.data.token,
            });
          });
          this.isBtnLoading = false;
        }
      } catch (error) {
        if (error.errors[0].error_name === "email") {
          this.$store.commit("user/killUser");
          this.$store.commit("pushToTray", {
            text: "Ваш email неверный или Вы входите в другой личный кабинет",
            type: "error",
          });
        } else if (error.errors[0].error_name === "telegram") {
          this.$store.commit("user/killUser");
          this.$store.commit("pushToTray", {
            text: "Высокая нагрузка на сервер. Попробуйте через 30 секунд",
            type: "error",
          });
        } else
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        this.isBtnLoading = false;
      }
    },
    captchaRequest() {
      this.isCaptchaLoading = true;
      this.getCaptcha()
        .then((result) => {
          this.captchaData = result;
        })
        .finally(() => {
          this.isCaptchaLoading = false;
        });
    },
    reloadCaptcha() {
      this.captchaRequest();
      this.reloadCaptchaAgree = false;
      const timer = setTimeout(() => {
        this.reloadCaptchaAgree = true;
        clearTimeout(timer);
      }, 60000);
    },
    openAuthInfoPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupAuthInfo",
        isShown: true,
        props: { popupClass: "popup-auth-info" },
      });
    },
    async sendCode() {
      clearTimeout(this.timer);
      this.isResendAgree = false;
      return createRequest(requestConfigs.POSTCodeRequest, {
        jsonPayload: {
          login: this.formData.emailValue,
          captcha: this.formData.captchaValue,
          captcha_secret: this.captchaData.secret,
          type_code: "signin",
        },
      }).then(() => {
        this.timer = setTimeout(() => {
          this.isResendAgree = true;
          clearTimeout(this.timer);
        }, (this.resendCodeSeconds ?? 60) * 1000);
      });
    },
    async sendCodeToEmail() {
      if (!this.isResendAgree) return;
      await this.sendCode()
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Код отправлен на Ваш Email",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        });
    },
    ...mapActions(["getCaptcha"]),
    checkTrueEmails,
  },
  watch: {
    authStatus() {
      if (!this.captchaData.imgBase64) this.captchaRequest();
    },
  },
  beforeMount() {
    if (this.authStatus !== "auth") return;
    if (!this.captchaData.imgBase64) this.captchaRequest();
  },
  mounted() {
    window.addEventListener("keydown", this.enterModal);
    this.checkEmails();
  },
  updated() {
    if (this.step === "code") {
      if (!this.$refs["email"].value) {
        this.$refs["email"]?.autofocus();
      }
    } else {
      if (!this.$refs["pinCode"].value) {
        this.$refs["pinCode"]?.autofocus();
      }
    }
  },
};
</script>

<style scoped></style>
