<template>
  <div class="comment">
    <div class="comment__title">
      <span><Icon class="icon-Person" /></span>
      <p>{{ status }} Комментарий ответственного:</p>
    </div>
    <div class="comment__text">
      {{ comment }}
    </div>
    <div class="comment__support">
      Если у Вас возникли вопросы по комментарию модератора - необходимо
      обращаться
      <a :href="supportLink" target="_blank">
        {{ supportLink }}
      </a>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Comment",
  components: { Icon },
  data() {
    return {
      supportMapper: {
        tg: "https://vk.com/transgrant",
        sg: "https://vk.com/studentgodart",
        dg: "https://vk.com/dostizheniye_goda",
        mzk: "https://vk.com/mol_ipoteka",
        lf: "https://vk.com/liga_forum",
      },
    };
  },
  computed: {
    supportLink() {
      return this.supportMapper[this.projectType];
    },
  },
  props: ["comment", "status", "projectType"],
};
</script>

<style lang="scss">
.comment {
  color: #eb6e40;
  background: #ffe4da;
  border-radius: 10px;
  padding: 24px 40px;
  &__title {
    display: flex;
    gap: 7px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  &__support {
    margin-top: 1rem;
    color: var(--black);
    a {
      &:hover {
        -webkit-text-stroke-width: 0.05rem;
      }
    }
  }
  @include adaptive(tablet-big) {
    padding: 16px 20px;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}
</style>
